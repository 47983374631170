import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

// eslint-disable-next-line
export default function Button({ href, url, name, color, small, ...restOfProps }) {
  let size = "px-4 py-3 text-lg inline-block";
  if (small) {
    size = "px-2 py-1 my-1 text-sm md:text-xs md:text-sm inline-block";
  }

  const styles = `button transition mr-2 ${color} ${size}`;

  if (href) {
    return (
      <a href={href} {...restOfProps} className={styles}>
        {name}
      </a>
    );
  }

  return (
    <Link {...restOfProps} className={styles} to={`${url}`}>
      {name}
    </Link>
  );
}

Button.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
};
Button.defaultProps = {
  small: false,
  url: "",
  color: "bg-secondary-light hover:bg-secondary text-gray-800",
};
