import React from "react";
import { Link } from "gatsby";
import routes from "../routes";
import { useCategories } from "../hooks/useCategories";

export default function Sidebar() {
  const categories = useCategories();

  return (
    <aside>
      <div className="mb-6 lg:mb-12">
        <h3 className="my-2 text-xl font-bold">Categories</h3>
        <ul className="list-none">
          {categories.map(({ name, slug }) => (
            <li className="mb-1 underline text-primary-dark hover:font-bold" key={slug}>
              <Link className="inline-block py-1" to={`${routes.blog}/${slug}/`}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}
