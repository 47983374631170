/* eslint-disable */
const slugify = require("slugify");
const routes = require("../routes");

module.exports.toSlug = title =>
  slugify(title, {
    lower: true,
  });

module.exports.categoryToSlug = category => `${routes.blog}/${this.toSlug(category)}`;
